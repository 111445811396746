import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import SmartWatch from '../assets/smartwatchblue.png'
import SmartWatchWhite from '../assets/smartwatchwhite.png'

import psIcon from '../assets/ps-icon.png'
import psLogo from '../assets/added-logo.png'
// import { icon } from '@fortawesome/fontawesome-svg-core'

class Sidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isNewWindow: false,
      secondWindow: null,
      isCollapsed: true,
      isDevicesClicked: false,
    }
  }

  toggleCollapse(e) {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ isDevicesClicked: false })
    }
  }

  render() {
    const { location, user } = this.props
    const loggedIn = user.id
    const hide = { display: 'none' }
    const show = { display: 'block' }
    const arrow = this.state.isCollapsed
      ? 'fa-chevron-double-right'
      : 'fa-chevron-double-left'

    const isDevicesActive =
      location.pathname === '/devices' || this.state.isDevicesClicked
    const smartwatchIcon = isDevicesActive ? SmartWatchWhite : SmartWatch

    // Define a style for each NavLink:
    // - Use flex to center content vertically.
    // - Add top and bottom padding.
    // Note: We do not include a "color" property here so that CSS rules (or bootstrap classes)
    // control the text/icon color (blue by default, white when active).
    const navLinkStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px 0', // 10px top and bottom padding
      textDecoration: 'none',
      marginBottom: '5px',
      marginTop: '5px'
    }

    // Define a style for the icons to provide additional spacing between the icon and text.
    const iconStyle = {
      marginBottom: '10px',
      fontSize: '1.5rem',
    }

    return (
      <div
        className='nav flex-md-column nav-pills mt-2'
        aria-orientation='vertical'
        style={{ height: '100%' }}
      >
        <div className='row d-flex align-items-center'>
          <a href='https://www.marketing.patientsafetyinc.com/'>
            <img
              className='ml-4 mb-4'
              src={psIcon}
              width='38'
              height='38'
              alt='PatientSafetyLogo'
            />
          </a>
          <a href='https://www.marketing.patientsafetyinc.com/'>
            <img
              className='mb-4'
              hidden={this.state.isCollapsed}
              src={psLogo}
              width='73.35'
              height='38'
              alt='PatientSafetyLogo'
            />
          </a>
          <i
            className={`col mr-2 mb-3 text-right btn btn-small fas ${arrow}`}
            onClick={this.toggleCollapse.bind(this)}
          ></i>
        </div>

        {/* Patients Link */}
        <NavLink
          className='nav-link'
          to='/patients'
          activeClassName='active'
          style={loggedIn ? { ...show, ...navLinkStyle } : hide}
        >
          <i className='fad fa-bed' style={iconStyle}></i>
          <span hidden={this.state.isCollapsed}>Patients</span>
        </NavLink>

        {/* Reports Link */}
        <NavLink
          className='nav-link'
          to='/reports'
          activeClassName='active'
          style={loggedIn ? { ...show, ...navLinkStyle } : hide}
        >
          <i className='far fa-file-chart-line' style={iconStyle}></i>
          <span hidden={this.state.isCollapsed}>Reports</span>
        </NavLink>

        {/* Login Link */}
        <NavLink
          className='nav-link'
          to='/login'
          activeClassName='active'
          style={loggedIn ? hide : { ...show, ...navLinkStyle }}
        >
          <FontAwesomeIcon style={iconStyle} icon={faSignInAlt} />
          <span hidden={this.state.isCollapsed}>Login</span>
        </NavLink>

        {/* Devices Link */}
        <NavLink
          className='nav-link'
          to='/devices'
          activeClassName='active'
          style={loggedIn ? { ...show, ...navLinkStyle } : hide}
          onMouseUp={() => this.setState({ isDevicesClicked: true })}
        >
          <img
            src={smartwatchIcon}
            alt='Devices'
            style={iconStyle}
            width='25'
          />
          <span hidden={this.state.isCollapsed}>Devices</span>
        </NavLink>

                {/* Connection Center */}
        {/* <NavLink
          className='nav-link'
          to='/connectionCenter'
          activeClassName='active'
          style={loggedIn ? { ...show, ...navLinkStyle } : hide}
          onMouseUp={() => this.setState({ isDevicesClicked: true })}
        >
          <i className='fa-solid fa-toolbox' style={iconStyle} />
          <span hidden={this.state.isCollapsed}>Devices</span>
        </NavLink> */}

        {/* Device Admin Link (visible only to super-admin) */}
        {user.email === 'super-admin@patientsafetyinc.com' && (
          <NavLink
            className='nav-link'
            to='/deviceAdmin'
            activeClassName='active'
            style={loggedIn ? { ...show, ...navLinkStyle } : hide}
            onMouseUp={() => this.setState({ isDevicesClicked: true })}
          >
            <i className='fa-solid fa-toolbox' style={iconStyle} />
            <span hidden={this.state.isCollapsed}>Device Admin</span>
          </NavLink>
        )}
      </div>
    )
  }
}

export default withRouter(Sidebar)
