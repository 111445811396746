import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

class DeviceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // The DeviceTable no longer manages its own sort state.
      // Sorting is handled externally (by the Paginator).
      
      // Org & Patient state
      patientSelects: {},
      currentOrgs: {},
      initialPatientOptions: {},
      // Hub state
      hubSelects: {},
      currentHubs: {}
    };
  }

  componentDidMount() {
    this.initializeOrgsAndPatients(this.props.devices);
    this.initializeHubs(this.props.devices);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.devices !== this.props.devices) {
      this.initializeOrgsAndPatients(this.props.devices);
      this.initializeHubs(this.props.devices);
    }
  }

  // ==========================================================
  // 1) ORGANIZATIONS & PATIENTS
  // ==========================================================

  initializeOrgsAndPatients(devices) {
    const currentOrgs = {};
    devices.forEach(device => {
      if (device.organization) {
        currentOrgs[device.id] = {
          value: device.organization.id,
          label: `${device.organization.name}${
            device.organization.email ? ` (${device.organization.email})` : ''
          }`
        };
      } else if (device.organizationId) {
        currentOrgs[device.id] = {
          value: device.organizationId,
          label: `Organization #${device.organizationId}`
        };
      } else {
        currentOrgs[device.id] = null;
      }
    });

    this.setState({ currentOrgs }, () => {
      devices.forEach(device => {
        const orgOption = this.state.currentOrgs[device.id];
        if (orgOption && orgOption.value) {
          this.loadInitialPatients(device.id, orgOption.value);
        }
      });
    });
  }

  loadOrgOptions = (inputValue) => {
    const query = inputValue || '';
    return axios
      .get(`/organizations?search=${encodeURIComponent(query)}&limit=1000`)
      .then(res => {
        const rows = res.data.rows || res.data;
        return rows.map(org => ({
          value: org.id,
          label: `${org.name}${org.email ? ` (${org.email})` : ''}`
        }));
      })
      .catch(error => {
        console.error('Error loading organizations:', error);
        return [];
      });
  };

  handleOrganizationChange = (deviceId, orgId) => {
    this.setState(
      prevState => ({
        patientSelects: {
          ...prevState.patientSelects,
          [deviceId]: Date.now()
        },
        currentOrgs: {
          ...prevState.currentOrgs,
          [deviceId]: orgId ? { value: orgId, label: `Organization #${orgId}` } : null
        },
        initialPatientOptions: {
          ...prevState.initialPatientOptions,
          [deviceId]: null
        }
      }),
      () => {
        if (this.props.onOrganizationChange) {
          this.props.onOrganizationChange(deviceId, orgId);
        }
        if (orgId) {
          this.loadInitialPatients(deviceId, orgId);
        }
      }
    );
  };

  loadInitialPatients = async (deviceId, organizationId) => {
    try {
      const response = await axios.get(
        `/patients?organizationId=${organizationId}&limit=1000`
      );
      const rows = response.data.rows || response.data;
      const options = rows.map(patient => ({
        value: patient.id,
        label: `${patient.firstName} ${patient.lastName}`
      }));

      this.setState(prevState => ({
        initialPatientOptions: {
          ...prevState.initialPatientOptions,
          [deviceId]: options
        }
      }));
    } catch (error) {
      console.error('Error loading initial patients:', error);
    }
  };

  loadPatientOptions = (deviceId, inputValue) => {
    const orgOption = this.state.currentOrgs[deviceId];
    const organizationId = orgOption ? orgOption.value : null;

    if (!organizationId) {
      return Promise.resolve([]);
    }

    // Return cached initial patients if there's no search input
    if (!inputValue && this.state.initialPatientOptions[deviceId]) {
      return Promise.resolve(this.state.initialPatientOptions[deviceId]);
    }

    return axios
      .get(
        `/patients?organizationId=${organizationId}&search=${encodeURIComponent(
          inputValue || ''
        )}&limit=1000`
      )
      .then(response => {
        const rows = response.data.rows || response.data;
        return rows.map(patient => ({
          value: patient.id,
          label: `${patient.firstName} ${patient.lastName}`
        }));
      })
      .catch(error => {
        console.error('Error loading patients:', error);
        return [];
      });
  };

  handlePatientChange = (deviceId, patientId) => {
    if (this.props.onPatientChange) {
      this.props.onPatientChange(deviceId, patientId);
    }
  };

  // ==========================================================
  // 2) HUBS
  // ==========================================================

  initializeHubs(devices) {
    const currentHubs = {};
    devices.forEach(device => {
      if (device.hub) {
        currentHubs[device.id] = {
          value: device.hub.id,
          label: device.hub.serialNumber || device.hub.name || `Hub #${device.hub.id}`
        };
      } else if (device.hubId) {
        currentHubs[device.id] = {
          value: device.hubId,
          label: `Hub #${device.hubId}`
        };
      } else {
        currentHubs[device.id] = null;
      }
    });

    this.setState({ currentHubs });
  }

  loadHubOptions = async (deviceId, inputValue) => {
    let allHubs = this.props.hubs;
    if (!allHubs || allHubs.length === 0) {
      const response = await axios.get(`/hubs?limit=1000`);
      allHubs = response.data.rows || response.data;
    }
    const query = inputValue.toLowerCase();
    let filteredHubs = allHubs.filter(h => {
      const serialNumber = h.serialNumber ? h.serialNumber.toLowerCase() : '';
      const name = h.name ? h.name.toLowerCase() : '';
      return serialNumber.includes(query) || name.includes(query);
    });
    const assignedHubIds = this.props.devices
      .filter(d => d.hubId && d.id !== deviceId)
      .map(d => d.hubId);
    const currentDeviceHub = this.state.currentHubs[deviceId];
    const currentDeviceHubId = currentDeviceHub ? currentDeviceHub.value : null;
    filteredHubs = filteredHubs.filter(h => {
      return !assignedHubIds.includes(h.id) || h.id === currentDeviceHubId;
    });
    return filteredHubs.map(h => ({
      value: h.id,
      label: h.serialNumber || h.name || `Hub #${h.id}`
    }));
  };

  handleHubChange = async (deviceId, newHubId) => {
    console.log(deviceId, newHubId);
    let newHubOption = null;
    if (newHubId) {
      const newHub = this.props.hubs.find(hub => hub.id === newHubId);
      newHubOption = {
        value: newHubId,
        label: newHub && newHub.serialNumber ? newHub.serialNumber : `Hub #${newHubId}`
      };
    }
    this.setState(prevState => ({
      hubSelects: {
        ...prevState.hubSelects,
        [deviceId]: Date.now()
      },
      currentHubs: {
        ...prevState.currentHubs,
        [deviceId]: newHubOption
      }
    }));

    const device = this.props.devices.find(d => d.id === deviceId);
    const oldHubId = device
      ? device.hubId || (device.hub && device.hub.id)
      : null;

    try {
      if (oldHubId && oldHubId !== newHubId) {
        await axios.put(`/hubs/${oldHubId}`, { deviceId: "0" }, {
          headers: { 'Content-Type': 'application/json' }
        });
      }
      if (newHubId && newHubId !== oldHubId) {
        await axios.put(`/hubs/${newHubId}`, { deviceId: deviceId }, {
          headers: { 'Content-Type': 'application/json' }
        });
      }
      if (this.props.onHubChange) {
        this.props.onHubChange(deviceId, newHubId === "0" ? null : newHubId);
      }
    } catch (error) {
      console.error('Error assigning/unassigning hub:', error);
    }
  };

  // ==========================================================
  // 3) CHECKBOXES (Selecting Devices)
  // ==========================================================

  handleSelectAll = event => {
    const { devices, onSelectionChange } = this.props;
    const isChecked = event.target.checked;
    devices.forEach(device => {
      onSelectionChange(device.id, isChecked);
    });
  };

  handleSelectOne = (event, deviceId) => {
    const isChecked = event.target.checked;
    this.props.onSelectionChange(deviceId, isChecked);
  };

  // ==========================================================
  // 4) SORTING (Headers use the provided sort state and callback)
  // ==========================================================
  renderSortIcon = (field) => {
    const { sortField, sortOrder } = this.props;
    if (sortField !== field) return null;
    return sortOrder === 'asc' ? ' ▲' : ' ▼';
  };

  // ==========================================================
  // 5) RENDER
  // ==========================================================

  render() {
    const { selectedDevices, devices } = this.props;
    const { patientSelects, currentOrgs, initialPatientOptions, hubSelects, currentHubs } = this.state;

    // The devices are assumed to be sorted by the parent (via the Paginator).
    const devicesCopy = [...devices];

    const allSelected = devicesCopy.length > 0 && devicesCopy.every(d => selectedDevices.has(d.id));
    const someSelected = devicesCopy.some(d => selectedDevices.has(d.id));

    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span className="h4">BLE Oximeters</span>
        </div>
        <div className="card-body">
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={allSelected}
                    ref={input => {
                      if (input) input.indeterminate = !allSelected && someSelected;
                    }}
                    onChange={this.handleSelectAll}
                  />
                </th>
                <th onClick={() => this.props.onSort('serialNumber')} style={{ cursor: 'pointer' }}>
                  Serial Number{this.renderSortIcon('serialNumber')}
                </th>
                <th onClick={() => this.props.onSort('hubSerial')} style={{ cursor: 'pointer', minWidth: '225px' }}>
                  Assigned Hub{this.renderSortIcon('hubSerial')}
                </th>
                <th onClick={() => this.props.onSort('patient')} style={{ cursor: 'pointer' }}>
                  Assigned Patient{this.renderSortIcon('patient')}
                </th>
                <th onClick={() => this.props.onSort('organization')} style={{ cursor: 'pointer' }}>
                  Organization{this.renderSortIcon('organization')}
                </th>
              </tr>
            </thead>
            <tbody>
              {devicesCopy.map(device => {
                const isSelected = selectedDevices.has(device.id);
                const orgOption = currentOrgs[device.id] || null;
                const patientSelectKey = `patient-${device.id}-${patientSelects[device.id] || 'initial'}`;
                const defaultPatientOptions = initialPatientOptions[device.id] || true;
                const hubOption = currentHubs[device.id] || null;
                const hubSelectKey = `hub-${device.id}-${hubSelects[device.id] || 'initial'}`;
                return (
                  <tr key={device.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={e => this.handleSelectOne(e, device.id)}
                      />
                    </td>
                    <td>{device.serialNumber}</td>
                    <td style={{ minWidth: 200 }}>
                      <AsyncSelect
                        key={hubSelectKey}
                        cacheOptions
                        loadOptions={inputValue => this.loadHubOptions(device.id, inputValue)}
                        defaultOptions
                        value={hubOption}
                        onChange={selectedOption => this.handleHubChange(device.id, selectedOption ? selectedOption.value : "0")}
                        isSearchable
                        placeholder="Select Hub..."
                        isClearable
                      />
                    </td>
                    <td style={{ minWidth: 200 }}>
                      <AsyncSelect
                        key={patientSelectKey}
                        cacheOptions
                        loadOptions={inputValue => this.loadPatientOptions(device.id, inputValue)}
                        defaultOptions={defaultPatientOptions}
                        value={
                          device.patient
                            ? {
                                value: device.patient.id,
                                label: `${device.patient.firstName} ${device.patient.lastName}`
                              }
                            : null
                        }
                        onChange={selectedOption => this.handlePatientChange(device.id, selectedOption ? selectedOption.value : "0")}
                        isSearchable
                        placeholder="Select Patient..."
                        isDisabled={!orgOption}
                        isClearable
                      />
                    </td>
                    <td style={{ minWidth: 200 }}>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={this.loadOrgOptions}
                        defaultOptions
                        value={orgOption}
                        onChange={selectedOption => this.handleOrganizationChange(device.id, selectedOption ? selectedOption.value : "0")}
                        isSearchable
                        placeholder="Select Organization..."
                        isClearable
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default DeviceTable;
