// HubItem.js
import React from 'react';

const HubItem = ({ hub, openEditHubModal, deleteHub }) => {
  // Extract devices from hub
  const device = hub.device || [];

  return (
    <tr>
      <td>{hub.serialNumber}</td>
      {/* <td>{hub.internalId}</td> */}
      <td>{device.serialNumber || 'N/A'} </td>
      <td>
        <button
          className='btn btn-sm btn-secondary mr-2'
          onClick={() => openEditHubModal(hub)}
        >
          Edit
        </button>
        {/* <button
          className='btn btn-sm btn-danger'
          onClick={() => deleteHub(hub.id)}
        >
          Delete
        </button> */}
      </td>
    </tr>
  );
};

export default HubItem;
