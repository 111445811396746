import React, { Component } from 'react';
import Papa from 'papaparse';

class CSVImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      errorMessage: '',
      parsedData: [],
      progress: 0,
      rowErrors: [],
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleFileChange(event) {
    this.setState({
      file: event.target.files[0],
      errorMessage: '',
      parsedData: [],
      progress: 0,
      rowErrors: [],
    });
  }

  async handleImport() {
    const { file } = this.state;
    const { type, entity, onImport } = this.props;
  
    if (!file) {
      this.setState({ errorMessage: 'Please select a CSV file to import.' });
      return;
    }
  
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;
  
        // Basic validation: ensure required columns are present
        const requiredColumns =
          entity === 'device'
            ? ['serialNumber', 'internalId' ] // Adjust based on your CSV structure
            : ['serialNumber', 'internalId' ];
  
        const missingColumns = requiredColumns.filter(
          (col) => !results.meta.fields.includes(col)
        );
        if (missingColumns.length > 0) {
          this.setState({
            errorMessage: `Missing columns: ${missingColumns.join(', ')}`,
          });
          return;
        }
  
        try {
          // Call onImport once with the entire data array
          await onImport(data, type, entity);
          // Update progress to 100% after successful import
          this.setState({ progress: 100 });
          alert('Import successful!');
        } catch (error) {
          console.error('Error during import:', error);
          this.setState({ errorMessage: 'There was an error during the import.' });
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        this.setState({ errorMessage: 'Failed to parse CSV file.' });
      },
    });
  }

  handleClose() {
    this.props.closeModal();
  }

  render() {
    const { type, entity, closeModal } = this.props;
    const { errorMessage, progress, rowErrors } = this.state;

    const modalTitle = `${type === 'create' ? 'Import' : 'Update'} ${
      entity === 'device' ? 'Devices' : 'Hubs'
    } via CSV`;

    return (
      <>
        {/* Modal Backdrop */}
        <div className="modal-backdrop show"></div>

        {/* Modal */}
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: 'block' }}
          aria-modal="true"
          onClick={closeModal}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">{modalTitle}</h3>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="csvFile">Choose CSV File</label>
                  <input
                    type="file"
                    className="form-control-file"
                    id="csvFile"
                    accept=".csv"
                    onChange={this.handleFileChange}
                  />
                </div>
                {progress > 0 && (
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                    >
                      {progress}%
                    </div>
                  </div>
                )}
                {rowErrors.length > 0 && (
                  <div className="alert alert-warning">
                    <h5>Errors:</h5>
                    <ul>
                      {rowErrors.map((err, index) => (
                        <li key={index}>
                          Row {err.row}: {err.error}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary" onClick={this.handleImport}>
                  Import
                </button>
                <button className="btn btn-secondary" onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CSVImportModal;
