// Devices.js
import React, { Component } from 'react';
import $ from 'jquery';
import DeviceItem from './device-item';
import HubItem from './hubItem';
import DeviceSelectModal from './deviceSelectModal';
import DeviceAddModal from './deviceAddModal';
import EditDeviceModal from './editDeviceModal';
import EditHubModal from './editHubModal'; 
import HubAddModal from './hubAddModal';
import Alert from './alert'; // Assuming you have an Alert component
import Paginator from './deviceAdmin/paginator';

class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      hubs: [],
      showDeviceSelectModal: false,
      showDeviceAddModal: false,
      showEditDeviceModal: false,
      showEditHubModal: false, 
      selectedDevice: null,
      selectedHub: null, 
      showHubAddModal: false,
      alertMessage: null,
      alertType: 'alert-success',
    };
    this.getDevices = this.getDevices.bind(this);
    this.getHubs = this.getHubs.bind(this);
    this.handleDevices = this.handleDevices.bind(this);
    this.handleHubs = this.handleHubs.bind(this);
    this.assignPatient = this.assignPatient.bind(this);
    this.deleteDevice = this.deleteDevice.bind(this);
    this.openDeviceAddModal = this.openDeviceAddModal.bind(this);
    this.openHubAddModal = this.openHubAddModal.bind(this);
    this.closeDeviceAddModal = this.closeDeviceAddModal.bind(this);
    this.closeHubAddModal = this.closeHubAddModal.bind(this);
    this.handleDeviceCreated = this.handleDeviceCreated.bind(this);
    this.handleHubCreated = this.handleHubCreated.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.handleDeviceUpdated = this.handleDeviceUpdated.bind(this);
    this.closeDeviceSelectModal = this.closeDeviceSelectModal.bind(this);
    this.openEditHubModal = this.openEditHubModal.bind(this);
    this.closeEditHubModal = this.closeEditHubModal.bind(this);
    this.handleHubUpdated = this.handleHubUpdated.bind(this);
    this.deleteHub = this.deleteHub.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
  }

  componentDidMount() {
    this.fetchAllData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.organization.id !== this.props.organization.id) {
      this.fetchAllData();
    }
  }

  fetchAllData() {
    this.getDevices();
    this.getHubs();
  }

  getDevices() {
    const url = `/devices?organizationId=${this.props.organization.id}&limit=1000`;
    $.get(url)
      .done(this.handleDevices)
      .fail((error) => {
        this.setState({
          alertMessage: 'Failed to fetch devices.',
          alertType: 'alert-danger',
        });
      });
  }

  getHubs() {
    const url = `/hubs?organizationId=${this.props.organization.id}&limit=1000`;
    $.get(url)
      .done(this.handleHubs)
      .fail((error) => {
        console.log('Error fetching hubs:', error);
        this.setState({
          alertMessage: 'Failed to fetch hubs.',
          alertType: 'alert-danger',
        });
      });
  }

  handleDevices(data) {
    // Map devices to include hubId
    const devicesWithHubId = data.rows.map(device => ({
      ...device,
      hubId: device.hub ? device.hub.id : null, // Assuming 'hub' is included
      patientName: device.patient ? `${device.patient.firstName} ${device.patient.lastName}` : null,
      hubSerialNumber: device.hub ? device.hub.serialNumber : null,
    }));
    this.setState({
      devices: devicesWithHubId,
    });
  }

  handleHubs(data) {
    // Map hubs to include patientName if needed
    const hubsWithPatientName = data.rows.map(hub => ({
      ...hub,
      patientName: hub.patient ? `${hub.patient.firstName} ${hub.patient.lastName}` : null,
    }));
    this.setState({
      hubs: hubsWithPatientName,
    });
  }

  assignPatient(device) {
    this.setState({
      selectedDevice: device,
      showDeviceSelectModal: true,
    });
  }

  deleteDevice(deviceId) {
    if (window.confirm('Are you sure you want to delete this device?')) {
      $.ajax({
        url: `/devices/${deviceId}`,
        method: 'DELETE',
      })
        .done(() => {
          this.fetchAllData();
          this.setState({
            alertMessage: 'Device deleted successfully.',
            alertType: 'alert-success',
          });
        })
        .fail((error) => {
          console.error('Error deleting device:', error);
          this.setState({
            alertMessage: 'Failed to delete device.',
            alertType: 'alert-danger',
          });
        });
    }
  }

  openHubAddModal() {
    this.setState({ showHubAddModal: true });
  }

  closeHubAddModal() {
    this.setState({ showHubAddModal: false });
  }

  handleHubCreated(newHub) {
    this.closeHubAddModal();
    this.fetchAllData();
    this.setState({
      alertMessage: 'Hub created successfully.',
      alertType: 'alert-success',
    });
  }

  openDeviceAddModal() {
    this.setState({ showDeviceAddModal: true });
  }

  closeDeviceAddModal() {
    this.setState({ showDeviceAddModal: false });
  }

  handleDeviceCreated(newDevice) {
    this.closeDeviceAddModal();
    this.fetchAllData();
    this.setState({
      alertMessage: 'Device created successfully.',
      alertType: 'alert-success',
    });
  }

  closeDeviceSelectModal() {
    this.setState({
      showDeviceSelectModal: false,
      selectedDevice: null,
    });
  }

  handleDeviceAssignment() {
    // This method can be used if needed, but currently, the parent handles the assignment
    this.fetchAllData();
    this.setState({
      showDeviceSelectModal: false,
      selectedDevice: null,
      alertMessage: 'Patient assigned successfully.',
      alertType: 'alert-success',
    });
  }

  handleDeviceSelection = (patient) => {
    const { selectedDevice, hubs } = this.state;
    
    if (!selectedDevice) {
      console.error('No device selected.');
      this.setState({
        alertMessage: 'No device selected.',
        alertType: 'alert-danger',
      });
      return;
    }

    // Update the Device's patient
    const deviceUpdateUrl = `/devices/${selectedDevice.id}`;
    const deviceData = {
      patientId: patient.id,
    };
    
    $.ajax({
      url: deviceUpdateUrl,
      method: 'PUT',
      data: deviceData,
    })
      .done(() => {
        
        // Identify the associated Hub using hubId
        const hubId = selectedDevice.hubId;
        const associatedHub = hubs.find(hub => hub.id === hubId);
        
        if (associatedHub) {
          const hubUpdateUrl = `/hubs/${associatedHub.id}`;
          const hubData = {
            patientId: patient.id,
          };
          
          $.ajax({
            url: hubUpdateUrl,
            method: 'PUT',
            data: hubData,
          })
            .done(() => {
              this.fetchAllData();
              this.closeDeviceSelectModal();
              this.setState({
                alertMessage: 'Patient assigned successfully to device and hub.',
                alertType: 'alert-success',
              });
            })
            .fail((error) => {
              console.error('Error updating hub patient:', error);
              this.setState({
                alertMessage: 'Failed to update hub patient.',
                alertType: 'alert-danger',
              });
              this.closeDeviceSelectModal();
            });
        } else {
          console.warn('No associated hub found for this device.');
          // Proceed without updating the hub
          this.fetchAllData();
          this.closeDeviceSelectModal();
          this.setState({
            alertMessage: 'Patient assigned successfully to device. No associated hub found.',
            alertType: 'alert-success',
          });
        }
      })
      .fail((error) => {
        console.error('Error assigning patient to device:', error);
        this.setState({
          alertMessage: 'Error assigning patient to device.',
          alertType: 'alert-danger',
        });
        this.closeDeviceSelectModal();
      });
  }

  openEditModal(device) {
    this.setState({ showEditDeviceModal: true, selectedDevice: device });
  }

  closeEditModal() {
    this.setState({ showEditDeviceModal: false, selectedDevice: null });
  }

  handleDeviceUpdated() {
    this.closeEditModal();
    this.fetchAllData();
    this.setState({
      alertMessage: 'Device updated successfully.',
      alertType: 'alert-success',
    });
  }

  // New methods for hub editing
  openEditHubModal(hub) {
    this.setState({ showEditHubModal: true, selectedHub: hub });
  }

  closeEditHubModal() {
    this.setState({ showEditHubModal: false, selectedHub: null });
  }

  handleHubUpdated() {
    this.closeEditHubModal();
    this.fetchAllData();
    this.setState({
      alertMessage: 'Hub updated successfully.',
      alertType: 'alert-success',
    });
  }

  deleteHub(hubId) {
    if (window.confirm('Are you sure you want to delete this hub?')) {
      $.ajax({
        url: `/hubs/${hubId}`,
        method: 'DELETE',
      })
        .done(() => {
          this.fetchAllData();
          this.setState({
            alertMessage: 'Hub deleted successfully.',
            alertType: 'alert-success',
          });
        })
        .fail((error) => {
          console.error('Error deleting hub:', error);
          this.setState({
            alertMessage: 'Failed to delete hub.',
            alertType: 'alert-danger',
          });
        });
    }
  }

  removeAlert() {
    this.setState({
      alertMessage: null,
    });
  }

  renderDeviceItem(device) {
    return (
      <DeviceItem
        key={device.id}
        device={device}
        assignPatient={this.assignPatient}
        deleteDevice={this.deleteDevice}
        openEditModal={this.openEditModal}
        hubs={this.state.hubs}
      />
    );
  }

  renderHubItem(hub) {
    return (
      <HubItem
        key={hub.id}
        hub={hub}
        openEditHubModal={this.openEditHubModal}
        deleteHub={this.deleteHub}
      />
    );
  }

  render() {
    const {
      devices,
      hubs,
      showDeviceSelectModal,
      showDeviceAddModal,
      showEditDeviceModal,
      showEditHubModal,
      selectedDevice,
      selectedHub,
      showHubAddModal,
      alertMessage,
      alertType,
    } = this.state;

    const deviceItems = devices.map(this.renderDeviceItem.bind(this));
    const hubItems = hubs.map(this.renderHubItem.bind(this));

    return (
      <div className="container-fluid">
        {/* Top Banner */}
        <div className='text-center mb-4'>
          Purchase the latest oximeters, accessories, and disposable probes at our online store:
          <a href='https://www.sleepsat.com/online-store' target="_blank" rel="noopener noreferrer"> www.sleepsat.com/online-store</a>
        </div>

        {/* Bootstrap Row to contain both Devices and Hubs */}
        <div className="row">
          {/* Devices Column */}
          <div className="col-md-6 mb-4">
            <div className="card">
              <div className='card-header d-flex justify-content-between align-items-center'>
                <span className='h4'>BLE Oximeter</span>
                {this.props.user?.email === 'super-admin@patientsafetyinc.com' && (
                  <button className='btn btn-primary' onClick={this.openDeviceAddModal}>
                    + Add Device
                  </button>
                )}
              </div>

              {/* Replace the old <table> with a Paginator that renders the table */}
              <Paginator
                data={devices}    // the full array from state
                limit={10}        // show 10 devices per page
                render={(limitedDevices) => (
                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Serial Number</th>
                          <th>Hub Serial Number</th>
                          <th>Assigned Patient</th>
                        </tr>
                      </thead>
                      <tbody>
                        {limitedDevices.map((device) => this.renderDeviceItem(device))}
                      </tbody>
                    </table>
                  </div>
                )}
              />
            </div>
          </div>

          {/* Hubs Column */}
          <div className="col-md-6 mb-4">
            <div className='card'>
              <div className='card-header d-flex justify-content-between align-items-center'>
                <span className='h4'>LTE Hubs</span>
                {this.props.user?.email === 'super-admin@patientsafetyinc.com' && (
                  <button className='btn btn-primary' onClick={this.openHubAddModal}>
                    + Add Hub
                  </button>
                )}
              </div>

              {/* Paginator for the hubs */}
              <Paginator
                data={hubs}
                limit={10}
                render={(limitedHubs) => (
                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Serial Number</th>
                          <th>Oximeter Serial Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {limitedHubs.map((hub) => this.renderHubItem(hub))}
                      </tbody>
                    </table>
                  </div>
                )}
              />
            </div>
          </div>

        </div>

        {/* Modals */}
        {showDeviceSelectModal && (
          <DeviceSelectModal
            device={selectedDevice}
            closeModal={this.closeDeviceSelectModal}
            onPatientSelect={this.handleDeviceSelection}
            organization={this.props.organization}
            user={this.props.user}
          />
        )}
        {showDeviceAddModal && (
          <DeviceAddModal
            closeModal={this.closeDeviceAddModal}
            organizationId={this.props.organization.id}
            onDeviceCreated={this.handleDeviceCreated}
          />
        )}
        {showEditDeviceModal && selectedDevice && (
          <EditDeviceModal
            closeModal={this.closeEditModal}
            device={selectedDevice}
            organizationId={this.props.organization.id}
            onDeviceUpdated={this.handleDeviceUpdated}
            hubs={this.state.hubs}
            organizations={this.props.organizations}
          />
        )}
        {showEditHubModal && selectedHub && (
          <EditHubModal
            closeModal={this.closeEditHubModal}
            hub={selectedHub}
            onHubUpdated={this.handleHubUpdated}
            organizations={this.props.organizations}
          />
        )}
        {showHubAddModal && ( // Correct conditional rendering
          <HubAddModal
            closeModal={this.closeHubAddModal}
            onHubCreated={this.handleHubCreated}
            organizationId={this.props.organization.id}
          />
        )}
        {/* Alert Component */}
        {alertMessage && (
          <Alert
            message={alertMessage}
            type={alertType}
            onExit={this.removeAlert}
          />
        )}
      </div>
    );
  }
}

export default Devices;
