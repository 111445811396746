import React, { Component } from 'react';

class HubTable extends Component {
  constructor(props) {
    super(props);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSelectOne = this.handleSelectOne.bind(this);
  }

  handleSelectAll(event) {
    const { hubs, onSelectionChange } = this.props;
    const isChecked = event.target.checked;
    hubs.forEach(hub => {
      onSelectionChange(hub.id, isChecked);
    });
  }

  handleSelectOne(event, hubId) {
    const isChecked = event.target.checked;
    this.props.onSelectionChange(hubId, isChecked);
  }

  // Render the sort icon based on props.
  renderSortIcon = (field) => {
    const { sortField, sortOrder } = this.props;
    if (sortField !== field) return null;
    return sortOrder === 'asc' ? ' ▲' : ' ▼';
  };

  render() {
    const { hubs, selectedHubs, onSort } = this.props;

    // Assume that hubs have already been sorted by the parent.
    // Determine checkbox states.
    const allSelected = hubs.length > 0 && hubs.every(hub => selectedHubs.has(hub.id));
    const someSelected = hubs.some(hub => selectedHubs.has(hub.id));

    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span className="h4">LTE Hubs</span>
        </div>
        <div className="card-body">
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={allSelected}
                    ref={input => {
                      if (input) input.indeterminate = !allSelected && someSelected;
                    }}
                    onChange={this.handleSelectAll}
                  />
                </th>
                <th onClick={() => onSort('serialNumber')} style={{ cursor: 'pointer' }}>
                  Serial Number{this.renderSortIcon('serialNumber')}
                </th>
                <th onClick={() => onSort('deviceSerial')} style={{ cursor: 'pointer' }}>
                  Oximeter Serial Number{this.renderSortIcon('deviceSerial')}
                </th>
                <th onClick={() => onSort('organization')} style={{ cursor: 'pointer' }}>
                  Organization{this.renderSortIcon('organization')}
                </th>
                <th onClick={() => onSort('patient')} style={{ cursor: 'pointer' }}>
                  Assigned Patient{this.renderSortIcon('patient')}
                </th>
              </tr>
            </thead>
            <tbody>
              {hubs.map(hub => (
                <tr key={hub.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedHubs.has(hub.id)}
                      onChange={(e) => this.handleSelectOne(e, hub.id)}
                    />
                  </td>
                  <td>{hub.serialNumber}</td>
                  <td>{hub.device ? hub.device.serialNumber : 'N/A'}</td>
                  <td>{hub.organization ? hub.organization.name : 'N/A'}</td>
                  <td>{hub.patient ? hub.patientName : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default HubTable;
