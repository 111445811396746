// EditHubModal.js
import React, { Component } from 'react';
import axios from 'axios';

class EditHubModal extends Component {
  constructor(props) {
    super(props);
    const { hub } = props;
    this.state = {
      serialNumber: hub.serialNumber || '',
      internalId: hub.internalId || '',
      organizationId: hub.organizationId || '',
      errorMessage: '',
      organizations: [], // Initialize organizations if not passed as a prop
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchOrganizations = this.fetchOrganizations.bind(this);
  }

  componentDidMount() {
    this.fetchOrganizations();
  }

  /**
   * Fetches the list of organizations if not already provided via props.
   */
  fetchOrganizations() {
    if (!this.props.organizations) {
      axios
        .get('/organizations')
        .then((response) => {
          this.setState({ organizations: response.data.rows });
        })
        .catch((error) => {
          console.error('Error fetching organizations:', error);
          this.setState({ errorMessage: 'Failed to fetch organizations.' });
        });
    } else {
      this.setState({ organizations: this.props.organizations });
    }
  }

  /**
   * Handles input changes for form fields.
   * @param {Object} event - The event object from the input field.
   */
  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  /**
   * Handles form submission to update the hub details.
   * Sends a PUT request to the server with the updated hub data.
   * @param {Object} event - The event object from the form submission.
   */
  handleSubmit(event) {
    event.preventDefault();
    const { serialNumber, internalId, organizationId } = this.state;
    const { hub } = this.props;

    // Basic validation to ensure required fields are filled
    if (!serialNumber || !internalId || !organizationId) {
      this.setState({ errorMessage: 'Please fill out all required fields.' });
      return;
    }

    const data = {
      serialNumber,
      internalId,
      organizationId,
    };

    axios
      .put(`/hubs/${hub.id}`, data)
      .then((response) => {
        // Notify the parent component that the hub has been updated
        if (this.props.onHubUpdated) {
          this.props.onHubUpdated();
        }
        // Close the modal
        if (this.props.closeModal) {
          this.props.closeModal();
        }
      })
      .catch((error) => {
        console.error('Error updating hub:', error);
        this.setState({ errorMessage: 'Failed to update hub. Please try again.' });
      });
  }

  render() {
    const {
      serialNumber,
      internalId,
      organizationId,
      errorMessage,
      organizations,
    } = this.state;
    const { closeModal } = this.props;

    return (
      <>
        {/* Modal Backdrop */}
        <div className="modal-backdrop show"></div>

        {/* Modal */}
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: 'block' }}
          aria-modal="true"
          onClick={closeModal} // Close modal when clicking outside the dialog
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dialog
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Edit Hub</h3>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="modal-body">
                  {/* Display error message if any */}
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  {/* Serial Number Field */}
                  <div className="form-group">
                    <label htmlFor="serialNumber">Serial Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="serialNumber"
                      name="serialNumber"
                      value={serialNumber}
                      onChange={this.handleInputChange}
                      required
                      disabled
                    />
                  </div>
                  {/* Internal ID Field */}
                  <div className="form-group">
                    <label htmlFor="internalId">Internal ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="internalId"
                      name="internalId"
                      value={internalId}
                      onChange={this.handleInputChange}
                      required
                      disabled
                    />
                  </div>
                  {/* Organization Selection */}
                  <div className="form-group">
                    <label htmlFor="organizationId">Organization</label>
                    <select
                      className="form-control"
                      id="organizationId"
                      name="organizationId"
                      value={organizationId}
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="">Select an organization</option>
                      {organizations.map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Add any additional fields for hubs here if needed */}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Update Hub
                  </button>
                  <button type="button" className="btn btn-secondary" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditHubModal;
