// DeviceSelectModal.js
import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import Alert from './alert';
import Search from './patient-search';
import Paginator from './paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ReactInputMask from 'react-input-mask';

class DeviceSelectModal extends Component {
  constructor(props) {
    super(props);
    this.url = '/patients';
    this.state = {
      patients: [],
      searched: [],
      limit: 10,
      offset: 0,
      limitedPatients: [],
      fetchingPatients: false,
      alertMessage: null,
      alertType: 'alert-success',
      showCreatePatientForm: false,
      selectedPatient: null,
      showConfirmation: false,
      newPatient: {
        firstName: '',
        middleName: '',
        lastName: '',
        birthDate: '',
        email: '',
        phone: '',
        heightFeet: '',
        heightInches: '',
        weight: '',
        neck: '',
        gender: '',
        maritalStatus: '',
      },
    };

    this.setFilteredPatients = this.setFilteredPatients.bind(this);
    this.dataHandler = this.dataHandler.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.clearOffset = this.clearOffset.bind(this);
    this.read = this.read.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('modal-open');
    this.read();
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open');
  }

  componentDidUpdate(prevProps) {
    let userUpdated = false;
    let organizationUpdated = false;

    if (this.props.user && prevProps.user) {
      userUpdated = this.props.user.id !== prevProps.user.id;
    }

    if (this.props.organization && prevProps.organization) {
      organizationUpdated = this.props.organization.id !== prevProps.organization.id;
    }

    if (userUpdated || organizationUpdated) {
      this.setState(
        {
          patients: [],
          offset: 0,
        },
        this.read
      );
    }
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      newPatient: {
        ...prevState.newPatient,
        [name]: value,
      },
    }));
  }

  renderPatientList() {
    const patients = this.state.limitedPatients.map(this.renderListItem);
    let conditionalData;
    if (this.state.searched.length > 0) {
      conditionalData = this.state.searched;
    } else {
      conditionalData = this.state.patients;
    }
  
    return (
      <div>
        <div className="d-flex align-items-center mb-3">
          <div className="flex-grow-1">
            <Search onSubmit={this.searchHandler} />
          </div>
          {!this.state.showCreatePatientForm && !this.state.showConfirmation && (
            <button
              className="btn btn-primary ml-2"
              onClick={this.toggleCreatePatientForm}
            >
              <FontAwesomeIcon className="mr-2" icon={faPlus} />
              Create New Patient
            </button>
          )}
        </div>
        <ul className="list-group">{patients}</ul>
        {this.state.patients.length > 0 && (
          <nav>
            <Paginator
              data={conditionalData}
              limit={this.state.limit}
              render={this.setFilteredPatients}
            />
          </nav>
        )}
      </div>
    );
  }

  handlePatientSelect = (patient) => {
    this.setState({ selectedPatient: patient, showConfirmation: true });
  };

  searchHandler = (event) => {
    event.preventDefault();
    const searchTermFirst = event.target.elements.firstName.value.toLowerCase();
    const searchTermLast = event.target.elements.lastName.value.toLowerCase();
    const filtered = this.state.patients.filter(
      (e) =>
        e.firstName.toLowerCase().includes(searchTermFirst) &&
        e.lastName.toLowerCase().includes(searchTermLast)
    );

    if (filtered.length < 1) {
      this.setState(
        {
          searched: [],
          searchMessage: 'No Results Found',
        },
        () => {
          // Clear the message after 3 seconds
          setTimeout(() => {
            this.setState({ searchMessage: '' });
          }, 3000);
        }
      );
    } else {
      this.setState({
        searched: filtered,
        searchMessage: '',
      });
    }
  };

  read(params = {}) {
    const success = this.dataHandler;
    const options = {
      organizationId: this.props.organization.id,
      limit: 100,
      offset: this.state.offset,
    };
    for (const key in params) {
      const value = params[key];
      if (value) options[key] = params[key];
    }

    $.ajax({
      method: 'GET',
      url: this.url,
      data: options,
    }).done(success).fail(error => {
      console.error('Error fetching patients:', error);
      this.setState({ alertMessage: 'Failed to fetch patients.', alertType: 'alert-danger' });
    });
  }

  dataHandler(data) {
    const newPatients = [...this.state.patients, ...data.rows];
    const hasMore = data.count > newPatients.length;

    this.setState(
      {
        patients: newPatients,
        offset: this.state.offset + 100,
        fetchingPatients: hasMore,
      },
      () => {
        if (hasMore) {
          this.read();
        }
      }
    );
  }

  removeAlert() {
    this.setState({
      alertMessage: null,
    });
  }

  setFilteredPatients(data) {
    const currentLimitedPatients = this.state.limitedPatients;
    const isDataChanged = JSON.stringify(currentLimitedPatients) !== JSON.stringify(data);

    if (isDataChanged) {
      this.setState({
        limitedPatients: data,
      });
    }
  }

  clearOffset() {
    this.setState({
      offset: 0,
    });
  }

  confirmAssignment = () => {
    const { selectedPatient } = this.state;
    if (selectedPatient) {
      this.props.onPatientSelect(selectedPatient);
      //this.setState({ showConfirmation: false, selectedPatient: null });
    }
  };

  cancelAssignment = () => {
    this.setState({ showConfirmation: false, selectedPatient: null });
  };

  renderListItem = (data) => {
    const id = data.id;
    const name = `${data.firstName} ${data.lastName}`;
    const birthDate = moment(data.birthDate).utc().format('MMMM D, YYYY');
    return (
      <li
        key={id}
        className="list-group-item list-group-item-action"
        onClick={() => this.handlePatientSelect(data)}
      >
        <div className="ml-4 row">
          <span>{name}</span>
        </div>
        <div className="ml-4 row">
          <small>{birthDate}</small>
        </div>
      </li>
    );
  };

  renderConfirmation = () => {
    const { selectedPatient } = this.state;
    const { device } = this.props;

    return (
      <div>
        <p>
          You are binding{' '}
          <strong>
            {selectedPatient.firstName} {selectedPatient.lastName}
          </strong>{' '}
          to device <strong>{device.serialNumber}</strong>. This will cause all studies from that
          device to be under this patient until the device is re-bound.
        </p>
        <button className="btn btn-primary mr-2" onClick={this.confirmAssignment}>
          Confirm
        </button>
        <button className="btn btn-secondary" onClick={this.cancelAssignment}>
          Cancel
        </button>
      </div>
    );
  };

  renderCreatePatientForm = () => {
    const handleChange = this.handleInputChange;
    return (
      <form onSubmit={this.handleCreatePatient}>
        {/* Patient form fields */}
        <div className="card-body">
          <div className="form-row">
            <div className="form-group col">
              <label>First Name</label>
              <input
                className="form-control"
                type="text"
                name="firstName"
                value={this.state.newPatient.firstName || ''}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col">
              <label>Middle Name</label>
              <input
                className="form-control"
                type="text"
                name="middleName"
                value={this.state.newPatient.middleName || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col">
              <label>Last Name</label>
              <input
                className="form-control"
                type="text"
                name="lastName"
                value={this.state.newPatient.lastName || ''}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {/* Other form fields */}
          {/* Height */}
          <div className="form-row">
            <div className="form-group col">
              <label>Height</label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min="0"
                  max="9"
                  name="heightFeet"
                  placeholder="Feet"
                  value={this.state.newPatient.heightFeet || ''}
                  onChange={handleChange}
                  style={{ width : '50px' }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">ft.</div>
                </div>
                <input
                  className="form-control"
                  type="number"
                  name="heightInches"
                  placeholder="Inches"
                  max="11"
                  value={this.state.newPatient.heightInches || ''}
                  onChange={handleChange}
                  style={{ width : '50px' }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">in.</div>
                </div>
              </div>
            </div>
            {/* Weight */}
            <div className="form-group col">
              <label>Weight</label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  name="weight"
                  placeholder="lbs."
                  value={this.state.newPatient.weight || ''}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">lbs.</div>
                </div>
              </div>
            </div>
            {/* Neck */}
            <div className="form-group col">
              <label>Neck</label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  name="neck"
                  placeholder="in."
                  value={this.state.newPatient.neck || ''}
                  onChange={handleChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">in.</div>
                </div>
              </div>
            </div>
            {/* Gender */}
            <div className="form-group col">
              <label>Sex</label>
              <select
                className="form-control"
                name="gender"
                value={this.state.newPatient.gender || 'Male'}
                onChange={handleChange}
              >
                <option>Male</option>
                <option>Female</option>
              </select>
            </div>
            {/* Marital Status */}
            <div className="form-group col">
              <label>Marital Status</label>
              <select
                className="form-control"
                name="maritalStatus"
                value={this.state.newPatient.maritalStatus || 'Married'}
                onChange={handleChange}
              >
                <option>Married</option>
                <option>Single</option>
                <option>Divorced</option>
                <option>Widowed</option>
              </select>
            </div>
          </div>
          {/* Contact Information */}
          <div className="form-row">
            <div className="form-group col">
              <label>Birth Date</label>
              <input
                className="form-control"
                type="date"
                name="birthDate"
                value={this.state.newPatient.birthDate || ''}
                onChange={handleChange}
                min="1000-01-01"
                max="3000-01-01"
                required
              />
            </div>
            <div className="form-group col">
              <label>Email</label>
              <input
                className="form-control"
                type="email"
                name="email"
                value={this.state.newPatient.email || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col">
              <label>Phone</label>
              <ReactInputMask
                className="form-control"
                type="text"
                name="phone"
                value={this.state.newPatient.phone || ''}
                onChange={handleChange}
                mask="(999) 999-9999"
              />
            </div>
          </div>
        </div>
        <div className="form-row justify-content-end">
          <button type="submit" className="btn btn-primary mr-2">
            Create Patient
          </button>
          <button type="button" className="btn btn-secondary" onClick={this.toggleCreatePatientForm}>
            Cancel
          </button>
        </div>
      </form>
    );
  };

  closeModal = () => {
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };

  toggleCreatePatientForm = () => {
    this.setState((prevState) => ({
      showCreatePatientForm: !prevState.showCreatePatientForm,
    }));
  };

  handleCreatePatient = (event) => {
    event.preventDefault();
    const { newPatient } = this.state;
  
    // Construct the data object with required fields
    const data = {
      firstName: newPatient.firstName,
      middleName: newPatient.middleName,
      lastName: newPatient.lastName,
      birthDate: newPatient.birthDate,
      gender: newPatient.gender || 'male',
      maritalStatus: newPatient.maritalStatus || 'married',
      email: newPatient.email,
      phone: newPatient.phone,
      organizationId: this.props.organization.id,
    };
  
    if (newPatient.heightFeet && newPatient.heightInches) {
      const feet = parseInt(newPatient.heightFeet, 10);
      const inches = parseInt(newPatient.heightInches, 10);
      if (!isNaN(feet) && !isNaN(inches)) {
        data.height = feet * 12 + inches;
      }
    }
    if (newPatient.neck) {
      data.neck = newPatient.neck;
    }
  
    const params = new URLSearchParams();
    for (const key in data) {
      params.append(key, data[key]);
    }
  
    axios
      .post('/patients', params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        const createdPatient = response.data;
        this.setState({
            selectedPatient: createdPatient,
            showConfirmation: true,
            showCreatePatientForm: false,
        });
      })
      .catch((error) => {
        console.error('Error creating patient:', error);
        this.setState({
          alertMessage: 'Failed to create patient.',
          alertType: 'alert-danger',
        });
      });
  };

  render() {
    const { showCreatePatientForm, showConfirmation } = this.state;

    return (
      <>
        {/* Modal Backdrop */}
        <div className="modal-backdrop show"></div>

        {/* Modal */}
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: 'block' }}
          aria-modal="true"
          onClick={this.closeModal}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">
                  {showCreatePatientForm
                    ? 'Create New Patient'
                    : showConfirmation
                    ? 'Confirm Assignment'
                    : 'Select Patient to Bind to Device'}
                </h3>
                <button type="button" className="close" onClick={this.closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/* Modal body content */}
              <div className="modal-body">
                {showCreatePatientForm
                  ? this.renderCreatePatientForm()
                  : showConfirmation
                  ? this.renderConfirmation()
                  : this.renderPatientList()}
              </div>
              <div className="modal-footer">
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={this.closeModal}>
                  Close
                </button>
              </div>
              </div>
            </div>
          </div>
        </div>
        {/* Alert component if needed */}
        <Alert
          message={this.state.alertMessage}
          type={this.state.alertType}
          onExit={this.removeAlert}
        />
      </>
    );
  }
}

export default DeviceSelectModal;
