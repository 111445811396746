import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import Papa from 'papaparse';
import DeviceTable from './DeviceTable';
import HubTable from './HubTable';
import BulkActionToolbar from './BulkAction';
import CSVImportModal from './CSVImportModal';
import Alert from './alert';
import Paginator from './paginator';

class AdminManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      hubs: [],
      selectedDevices: new Set(),
      selectedHubs: new Set(),
      showCSVImportModal: false,
      csvImportType: '',
      csvImportEntity: '',
      alertMessage: null,
      alertType: 'alert-success',
      // Device search fields
      deviceSerialSearchTerm: '',
      devicePatientSearchTerm: '',
      deviceOrganizationSearchTerm: '',
      deviceHubSearchTerm: '',
      // Hub search fields
      hubSerialSearchTerm: '',
      hubOrganizationSearchTerm: '',
      hubPatientSearchTerm: '',
      hubDeviceSerialSearchTerm: '',
    };

    // Bind existing methods
    this.getDevices = this.getDevices.bind(this);
    this.getHubs = this.getHubs.bind(this);
    this.handleDeviceSelection = this.handleDeviceSelection.bind(this);
    this.handleHubSelection = this.handleHubSelection.bind(this);
    this.openCSVImportModal = this.openCSVImportModal.bind(this);
    this.closeCSVImportModal = this.closeCSVImportModal.bind(this);
    this.handleCSVImport = this.handleCSVImport.bind(this);
    this.onExportDevices = this.onExportDevices.bind(this);
    this.onExportHubs = this.onExportHubs.bind(this);
    this.onDeleteDevices = this.onDeleteDevices.bind(this);
    this.onDeleteHubs = this.onDeleteHubs.bind(this);
    this.onOrganizationChange = this.onOrganizationChange.bind(this);
    this.onPatientChange = this.onPatientChange.bind(this);

    // Bind new device search handlers
    this.handleDeviceSerialSearchChange = this.handleDeviceSerialSearchChange.bind(this);
    this.handleDevicePatientSearchChange = this.handleDevicePatientSearchChange.bind(this);
    this.handleDeviceOrganizationSearchChange = this.handleDeviceOrganizationSearchChange.bind(this);
    this.handleDeviceHubSearchChange = this.handleDeviceHubSearchChange.bind(this);

    // Bind new hub search handlers
    this.handleHubSerialSearchChange = this.handleHubSerialSearchChange.bind(this);
    this.handleHubOrganizationSearchChange = this.handleHubOrganizationSearchChange.bind(this);
    this.handleHubPatientSearchChange = this.handleHubPatientSearchChange.bind(this);
    this.handleHubDeviceSearchChange = this.handleHubDeviceSearchChange.bind(this);
  }

  componentDidMount() {
    this.fetchAllData();
  }

  fetchAllData() {
    this.getDevices();
    this.getHubs();
  }

  getDevices() {
    const url = `/devices?limit=1000`;
    $.get(url)
      .done((data) => {
        // Enrich each device with extra fields for filtering
        const devicesWithExtra = data.rows.map((device) => ({
          ...device,
          hubId: device.hub ? device.hub.id : null,
          patientName: device.patient
            ? `${device.patient.firstName} ${device.patient.lastName}`
            : '',
          hubSerialNumber: device.hub ? device.hub.serialNumber : '',
        }));
        this.setState({ devices: devicesWithExtra });
      })
      .fail(() => {
        this.setState({
          alertMessage: 'Failed to fetch devices.',
          alertType: 'alert-danger',
        });
      });
  }

  getHubs() {
    const url = `/hubs?limit=1000`;
    $.get(url)
      .done((data) => {
        // Enrich each hub with a patientName field for filtering.
        // Note: For device serial numbers on hubs, we assume the hub record may include a device object.
        const hubsWithExtra = data.rows.map((hub) => ({
          ...hub,
          patientName: hub.patient
            ? `${hub.patient.firstName} ${hub.patient.lastName}`
            : '',
        }));
        this.setState({ hubs: hubsWithExtra });
      })
      .fail(() => {
        this.setState({
          alertMessage: 'Failed to fetch hubs.',
          alertType: 'alert-danger',
        });
      });
  }

  // Selection handlers
  handleDeviceSelection(deviceId, isSelected) {
    this.setState((prevState) => {
      const selectedDevices = new Set(prevState.selectedDevices);
      isSelected ? selectedDevices.add(deviceId) : selectedDevices.delete(deviceId);
      return { selectedDevices };
    });
  }

  handleHubSelection(hubId, isSelected) {
    this.setState((prevState) => {
      const selectedHubs = new Set(prevState.selectedHubs);
      isSelected ? selectedHubs.add(hubId) : selectedHubs.delete(hubId);
      return { selectedHubs };
    });
  }

  // CSV Import Modal
  openCSVImportModal(type, entity) {
    this.setState({
      showCSVImportModal: true,
      csvImportType: type,
      csvImportEntity: entity,
    });
  }
  closeCSVImportModal() {
    this.setState({
      showCSVImportModal: false,
      csvImportType: '',
      csvImportEntity: '',
    });
  }

  // Existing device organization and patient change handlers remain unchanged
  onOrganizationChange(deviceId, newOrgId) {
    axios
      .put(`/devices/${deviceId}`, {
        organizationId: newOrgId,
        updateHub: true,
      })
      .then(() => {
        this.setState({
          alertMessage: 'Device organization updated successfully.',
          alertType: 'alert-success',
        });
        this.fetchAllData();
      })
      .catch((error) => {
        console.error('Error updating device organization:', error);
        this.setState({
          alertMessage: 'Error updating device organization.',
          alertType: 'alert-danger',
        });
      });
  }

  onPatientChange(deviceId, newPatientId) {
    axios
      .put(`/devices/${deviceId}`, {
        patientId: newPatientId,
        updateHub: true,
      })
      .then(() => {
        this.setState({
          alertMessage: 'Device patient updated successfully.',
          alertType: 'alert-success',
        });
        this.fetchAllData();
      })
      .catch((error) => {
        console.error('Error updating device patient:', error);
        this.setState({
          alertMessage: 'Error updating device patient.',
          alertType: 'alert-danger',
        });
      });
  }

  async onDeleteHubs() {
    const { selectedHubs } = this.state;
    if (selectedHubs.size === 0) {
      this.setState({
        alertMessage: 'No hubs selected for deletion.',
        alertType: 'alert-warning',
      });
      return;
    }
    const hubIds = Array.from(selectedHubs);
    try {
      await Promise.all(hubIds.map((id) => axios.delete(`/hubs/${id}`)));
      this.setState({
        selectedHubs: new Set(),
        alertMessage: 'Selected hubs deleted successfully.',
        alertType: 'alert-success',
      });
      this.fetchAllData();
    } catch (error) {
      console.error('Error deleting hubs:', error);
      this.setState({
        alertMessage: 'Error deleting hubs. Please try again.',
        alertType: 'alert-danger',
      });
    }
  }

  async onDeleteDevices() {
    const { selectedDevices } = this.state;
    if (selectedDevices.size === 0) {
      this.setState({
        alertMessage: 'No devices selected for deletion.',
        alertType: 'alert-warning',
      });
      return;
    }
    const deviceIds = Array.from(selectedDevices);
    try {
      await Promise.all(deviceIds.map((id) => axios.delete(`/devices/${id}`)));
      this.setState({
        selectedDevices: new Set(),
        alertMessage: 'Selected devices deleted successfully.',
        alertType: 'alert-success',
      });
      this.fetchAllData();
    } catch (error) {
      console.error('Error deleting devices:', error);
      this.setState({
        alertMessage: 'Error deleting devices. Please try again.',
        alertType: 'alert-danger',
      });
    }
  }



  // CSV Import
  async handleCSVImport(csvData, type, entity) {
    this.setState({ isImporting: true, alertMessage: null });
    const results = [];

    const processItem = async (item, index) => {
      try {
        if (entity === 'hub') {
          if (type === 'create') {
            await axios.post('/hubs', item);
            results.push({
              index: index + 1,
              status: 'Success',
              message: `Hub "${item.serialNumber}" created successfully.`,
            });
          } else if (type === 'update') {
            if (!item.id) throw new Error('Missing id for hub update.');
            await axios.put(`/hubs/${item.id}`, item);
            results.push({
              index: index + 1,
              status: 'Success',
              message: `Hub "${item.serialNumber}" updated successfully.`,
            });
          }
        } else if (entity === 'device') {
          if (type === 'create') {
            await axios.post('/devices', item);
            results.push({
              index: index + 1,
              status: 'Success',
              message: `Device "${item.serialNumber}" created successfully.`,
            });
          } else if (type === 'update') {
            if (!item.id) throw new Error('Missing id for device update.');
            await axios.put(`/devices/${item.id}`, item);
            results.push({
              index: index + 1,
              status: 'Success',
              message: `Device "${item.serialNumber}" updated successfully.`,
            });
          }
        }
      } catch (error) {
        console.error(`Error processing ${entity} at row ${index + 1}:`, error);
        const errorMsg =
          error.response?.data?.message || error.message || 'Unknown error.';
        results.push({
          index: index + 1,
          status: 'Failure',
          message: `Failed to process "${item.serialNumber}": ${errorMsg}`,
        });
      }
    };

    for (let i = 0; i < csvData.length; i++) {
      await processItem(csvData[i], i);
    }

    this.setState({
      alertMessage: 'CSV import completed.',
      alertType: 'alert-info',
      importResults: results,
      isImporting: false,
    });

    this.fetchAllData();
    this.closeCSVImportModal();
  }

  // Exports remain unchanged
  onExportDevices() {
    const { selectedDevices, devices } = this.state;
    const selectedData = devices.filter((device) =>
      selectedDevices.has(device.id)
    );
    if (selectedData.length === 0) {
      this.setState({
        alertMessage: 'No devices selected for export.',
        alertType: 'alert-warning',
      });
      return;
    }
    const transformedData = selectedData.map((device) => ({
      id: device.id,
      serialNumber: device.serialNumber,
      internalId: device.internalId,
      probeSN: device.probeSN,
      firmwareVersion: device.firmwareVersion,
      purchaseDate: device.purchaseDate,
      organizationId: device.organizationId,
      organizationName: device.organization?.name || '',
      organizationEmail: device.organization?.email || '',
      patientId: device.patientId,
      patientFirstName: device.patient?.firstName || '',
      patientLastName: device.patient?.lastName || '',
      createdAt: device.createdAt,
      updatedAt: device.updatedAt,
      hubId: device.hubId,
      hubSerialNumber: device.hubSerialNumber,
      patientName: device.patientName,
    }));
    const csv = Papa.unparse(transformedData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `DeviceExport_${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.setState({
      alertMessage: 'Device export completed successfully.',
      alertType: 'alert-success',
    });
  }

  onExportHubs() {
    const { selectedHubs, hubs } = this.state;
    const selectedData = hubs.filter((hub) => selectedHubs.has(hub.id));
    if (selectedData.length === 0) {
      this.setState({
        alertMessage: 'No hubs selected for export.',
        alertType: 'alert-warning',
      });
      return;
    }
    const transformedData = selectedData.map((hub) => ({
      id: hub.id,
      serialNumber: hub.serialNumber,
      internalId: hub.internalId,
      firmwareVersion: hub.firmwareVersion,
      purchaseDate: hub.purchaseDate,
      organizationId: hub.organizationId,
      organizationName: hub.organization?.name || '',
      organizationEmail: hub.organization?.email || '',
      patientId: hub.patientId,
      patientFirstName: hub.patient?.firstName || '',
      patientLastName: hub.patient?.lastName || '',
      createdAt: hub.createdAt,
      updatedAt: hub.updatedAt,
      patientName: hub.patientName,
    }));
    const csv = Papa.unparse(transformedData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `HubExport_${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.setState({
      alertMessage: 'Hub export completed successfully.',
      alertType: 'alert-success',
    });
  }

  // ================================================
  // New search field change handlers for devices
  // ================================================
  handleDeviceSerialSearchChange(e) {
    this.setState({ deviceSerialSearchTerm: e.target.value });
  }
  handleDevicePatientSearchChange(e) {
    this.setState({ devicePatientSearchTerm: e.target.value });
  }
  handleDeviceOrganizationSearchChange(e) {
    this.setState({ deviceOrganizationSearchTerm: e.target.value });
  }
  handleDeviceHubSearchChange(e) {
    this.setState({ deviceHubSearchTerm: e.target.value });
  }

  // ================================================
  // New search field change handlers for hubs
  // ================================================
  handleHubSerialSearchChange(e) {
    this.setState({ hubSerialSearchTerm: e.target.value });
  }
  handleHubOrganizationSearchChange(e) {
    this.setState({ hubOrganizationSearchTerm: e.target.value });
  }
  handleHubPatientSearchChange(e) {
    this.setState({ hubPatientSearchTerm: e.target.value });
  }
  handleHubDeviceSearchChange(e) {
    this.setState({ hubDeviceSerialSearchTerm: e.target.value });
  }

  render() {
    const {
      devices,
      hubs,
      selectedDevices,
      selectedHubs,
      showCSVImportModal,
      csvImportType,
      csvImportEntity,
      alertMessage,
      alertType,
      // Device search state
      deviceSerialSearchTerm,
      devicePatientSearchTerm,
      deviceOrganizationSearchTerm,
      deviceHubSearchTerm,
      // Hub search state
      hubSerialSearchTerm,
      hubOrganizationSearchTerm,
      hubPatientSearchTerm,
      hubDeviceSerialSearchTerm,
    } = this.state;

    // ---------------------------
    // Filter Devices
    // ---------------------------
    const filteredDevices = devices.filter((device) => {
      const serialMatch = deviceSerialSearchTerm
        ? device.serialNumber &&
          device.serialNumber.toLowerCase().includes(deviceSerialSearchTerm.toLowerCase())
        : true;
      const patientMatch = devicePatientSearchTerm
        ? device.patientName &&
          device.patientName.toLowerCase().includes(devicePatientSearchTerm.toLowerCase())
        : true;
      const orgMatch = deviceOrganizationSearchTerm
        ? device.organization &&
          device.organization.name &&
          device.organization.name.toLowerCase().includes(deviceOrganizationSearchTerm.toLowerCase())
        : true;
      const hubMatch = deviceHubSearchTerm
        ? device.hubSerialNumber &&
          device.hubSerialNumber.toLowerCase().includes(deviceHubSearchTerm.toLowerCase())
        : true;
      return serialMatch && patientMatch && orgMatch && hubMatch;
    });

    // ---------------------------
    // Filter Hubs
    // ---------------------------
    const filteredHubs = hubs.filter((hub) => {
      const serialMatch = hubSerialSearchTerm
        ? hub.serialNumber &&
          hub.serialNumber.toLowerCase().includes(hubSerialSearchTerm.toLowerCase())
        : true;
      const orgMatch = hubOrganizationSearchTerm
        ? hub.organization &&
          hub.organization.name &&
          hub.organization.name.toLowerCase().includes(hubOrganizationSearchTerm.toLowerCase())
        : true;
      const patientMatch = hubPatientSearchTerm
        ? hub.patientName &&
          hub.patientName.toLowerCase().includes(hubPatientSearchTerm.toLowerCase())
        : true;
      const deviceSerialMatch = hubDeviceSerialSearchTerm
        ? hub.device &&
          hub.device.serialNumber &&
          hub.device.serialNumber.toLowerCase().includes(hubDeviceSerialSearchTerm.toLowerCase())
        : true;
      return serialMatch && orgMatch && patientMatch && deviceSerialMatch;
    });

    return (
      <div className="container-fluid">
        {/* Alert */}
        {alertMessage && (
          <Alert
            message={alertMessage}
            type={alertType}
            onExit={() => this.setState({ alertMessage: null })}
          />
        )}

        {/* Bulk Action Toolbar */}
        <BulkActionToolbar
          onCreate={(entity) => this.openCSVImportModal('create', entity)}
          onUpdate={(entity) => this.openCSVImportModal('update', entity)}
          onExportDevices={this.onExportDevices}
          onExportHubs={this.onExportHubs}
          onDeleteDevices={this.onDeleteDevices}
          onDeleteHubs={this.onDeleteHubs}
          disableExportDevices={selectedDevices.size === 0}
          disableExportHubs={selectedHubs.size === 0}
        />

        <div className="row">
          {/* DEVICE TABLE COLUMN */}
          <div className="col-md-6">
            {/* Device Search Fields */}
            <div className="mb-3">
              <h4>Device Search</h4>
              <div className="form-row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Device Serial..."
                    value={deviceSerialSearchTerm}
                    onChange={this.handleDeviceSerialSearchChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Assigned Patient..."
                    value={devicePatientSearchTerm}
                    onChange={this.handleDevicePatientSearchChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Organization..."
                    value={deviceOrganizationSearchTerm}
                    onChange={this.handleDeviceOrganizationSearchChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Hub Serial..."
                    value={deviceHubSearchTerm}
                    onChange={this.handleDeviceHubSearchChange}
                  />
                </div>
              </div>
            </div>
            {/* Device Table */}
            <Paginator
              data={filteredDevices}
              limit={10}
              defaultSortField="organization"
              defaultSortOrder="desc"
              render={(limitedDevices, {sortField, sortOrder, handleSort}) => (
                <DeviceTable
                  devices={limitedDevices}
                  hubs={hubs}
                  selectedDevices={selectedDevices}
                  onSelectionChange={this.handleDeviceSelection}
                  onOrganizationChange={this.onOrganizationChange}
                  onPatientChange={this.onPatientChange}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSort={handleSort}

                />
              )}
            />
          </div>

          {/* HUB TABLE COLUMN */}
          <div className="col-md-6">
            {/* Hub Search Fields (aligned right) */}
            <div className="mb-3 text-left">
              <h4>Hub Search</h4>
              <div className="form-row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Hub Serial..."
                    value={hubSerialSearchTerm}
                    onChange={this.handleHubSerialSearchChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Organization..."
                    value={hubOrganizationSearchTerm}
                    onChange={this.handleHubOrganizationSearchChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Assigned Patient..."
                    value={hubPatientSearchTerm}
                    onChange={this.handleHubPatientSearchChange}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Device Serial..."
                    value={hubDeviceSerialSearchTerm}
                    onChange={this.handleHubDeviceSearchChange}
                  />
                </div>
              </div>
            </div>
            {/* Hub Table */}
            <Paginator
              data={filteredHubs}
              limit={10}
              defaultSortField="organization"
              defaultSortOrder="desc"
              render={(limitedHubs, {sortField, sortOrder, handleSort}) => (
                <HubTable
                  hubs={limitedHubs}
                  selectedHubs={selectedHubs}
                  onSelectionChange={this.handleHubSelection}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSort={handleSort}
                />
              )}
            />
          </div>
        </div>

        {/* CSV Import Modal */}
        {showCSVImportModal && (
          <CSVImportModal
            type={csvImportType}
            entity={csvImportEntity}
            closeModal={this.closeCSVImportModal}
            onImport={this.handleCSVImport}
          />
        )}
      </div>
    );
  }
}

export default AdminManager;
