// BulkActionToolbar.js
import React from 'react';
import PropTypes from 'prop-types';

const BulkActionToolbar = ({
  onCreate,
  onUpdate,
  onDeleteDevices,
  onDeleteHubs,
  onExportDevices, // New prop
  onExportHubs,    // New prop
  disableDeleteDevices,
  disableDeleteHubs,
}) => {
  return (
    <div className="bulk-action-toolbar mb-3">
      <div className="btn-group mr-2" role="group" aria-label="Bulk actions for Devices">
        <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Devices Actions
        </button>
        <div className="dropdown-menu">
          <button className="dropdown-item" onClick={() => onCreate('device')}>Create Devices</button>
          <button className="dropdown-item" onClick={() => onUpdate('device')}>Update Devices</button>
          <button className="dropdown-item" onClick={() => onExportDevices()}>Export Devices to CSV</button> {/* New Export Option */}
          <button className="dropdown-item" onClick={() => onDeleteDevices()} disabled={disableDeleteDevices}>Delete Selected Devices</button>
        </div>
      </div>

      <div className="btn-group mr-2" role="group" aria-label="Bulk actions for Hubs">
        <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Hubs Actions
        </button>
        <div className="dropdown-menu">
          <button className="dropdown-item" onClick={() => onCreate('hub')}>Create Hubs</button>
          <button className="dropdown-item" onClick={() => onUpdate('hub')}>Update Hubs</button>
          <button className="dropdown-item" onClick={() => onExportHubs()}>Export Hubs to CSV</button> {/* New Export Option */}
          <button className="dropdown-item" onClick={() => onDeleteHubs()} disabled={disableDeleteHubs}>Delete Selected Hubs</button>
        </div>
      </div>
    </div>
  );
};

BulkActionToolbar.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDeleteDevices: PropTypes.func.isRequired,
  onDeleteHubs: PropTypes.func.isRequired,
  onExportDevices: PropTypes.func.isRequired, // New prop type
  onExportHubs: PropTypes.func.isRequired,    // New prop type
  disableDeleteDevices: PropTypes.bool.isRequired,
  disableDeleteHubs: PropTypes.bool.isRequired,
};

export default BulkActionToolbar;
