import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Dropdown } from 'antd';
import { allEventChartIcon } from './utils';
import { globalData } from './globals';

const eventsList = [
  {
    title: 'Position',
    show: true,
  },
  {
    title: 'Sleep Staging',
    show: true,
  },
  {
    title: 'Arousals',
    show: true,
  },
  {
    title: 'Respiratory Events',
    noBox: true,
  },
  {
    title: 'Rera',
    show: true,
    suboption: true,
    signalName: 'Airflow',
    value: 'RDI_RERA',
  },
  {
    title: 'Apneas',
    show: true,
    suboption: true,
    signalName: 'Airflow',
    value: 'CentralApnea',
    sameRow: 'Rera',
  },
  {
    title: 'Hypopnea',
    show: true,
    suboption: true,
    value: 'Hypopnea',
    signalName: 'Airflow',
    sameRow: 'Rera',
  },
  {
    title: 'Snoring',
    signalName: 'Snore',
    value: 'SnoreEvent',
  },
  {
    title: 'Spo2',
    noBox: true,
  },
  {
    title: 'SpO2 Instability',
    show: true,
    suboption: true,
    signalName: 'SPO2',
    value: 'OximetryCycling',
  },
  {
    title: 'Pulse',
    noBox: true,
  },
  {
    title: 'Bradycardia',
    show: true,
    suboption: true,
    signalName: 'Pulse',
    value: 'Bradycardia',
  },
  {
    title: 'Tachycardia',
    show: true,
    suboption: true,
    signalName: 'Pulse',
    value: 'Tachycardia',
    sameRow: 'Bradycardia',
  },
  {
    title: 'Pulse Instability',
    show: true,
    suboption: true,
    signalName: 'Pulse',
    sameRow: 'Bradycardia',
    value: 'PulseCycling',
  },
];

const compare = (prevProps, nextProps) => {
  if (!prevProps.selectedChartIds?.length) return false;
  if (prevProps.selectedChartIds?.length !== nextProps.selectedChartIds?.length) return false;
  return true;
};

export const CustomDropdownEvents = React.memo((props) => {
  const { drawAllEventsChart, visibleEvents, selectedChartIds } = props;
  const [visibleEventTypesSelectorOpen, setVisibleEventTypesSelectorOpen] = useState(false);
  const [n, setN] = useState(1);

  useEffect(() => {
    // const coords = document.getElementById('event_selector_button2').getBoundingClientRect();
    // const listElement = document.getElementById('event_selector_list2');
    // listElement.style.top = coords.bottom + 'px';
    // listElement.style.left = coords.left - 80 + 'px';
    globalData.firstChartEvents = eventsList;
  }, []);

  useEffect(() => {
    if (n === 1 && globalData.allEventTypesList?.length) {
      eventsList.forEach((e, i) => {
        if (e.suboption) {
          if (!globalData.allEventTypesList.includes(e.value)) eventsList[i].show = false;
        }
      });
      if (
        globalData.pulseSignalId &&
        globalData.loadedEventsData[globalData.pulseSignalId] &&
        globalData.loadedEventsData[globalData.pulseSignalId].find((ev) => ev.customName === 'Fall')
      )
        eventsList[11].show = true;
      if (
        globalData.pulseSignalId &&
        globalData.loadedEventsData[globalData.pulseSignalId] &&
        globalData.loadedEventsData[globalData.pulseSignalId].find((ev) => ev.customName === 'Small Rise')
      )
        eventsList[12].show = true;
      eventsList[0].show = globalData.positionSignal;
      eventsList[5].show = globalData.eventsTyped['CentralApnea'] && globalData.eventsTyped['CentralApnea'].length;
      eventsList[6].show = globalData.eventsTyped['Hypopnea'] && globalData.eventsTyped['Hypopnea'].length;
      eventsList[4].show = globalData.eventsTyped['RDI_RERA'] && globalData.eventsTyped['RDI_RERA'].length;
      globalData.firstChartEvents = eventsList;
      setN((n) => n + 1);
    }
  }, [selectedChartIds, n]);

  const updateEventShow = useCallback((i, show) => {
    globalData.firstChartEvents[i].show = show;
    drawAllEventsChart();
    setN((n) => n + 1);
  });

  const eventVisibleItems = useMemo(() => {
    return globalData.firstChartEvents.map((t, i) => {
      return {
        key: i,
        label: t.noBox ? (
          <div>{t.title}</div>
        ) : (
          <div
            className={'event_option ' + (t.suboption ? 'suboption' : '')}
            onClick={() => {
              if (!t.box) updateEventShow(i, !t.show);
            }}
          >
            {t.title}
            <Checkbox checked={t.show}></Checkbox>
          </div>
        ),
        disabled: false,
      };
    });
  }, [selectedChartIds, n]);

  const eventVisibleSelectorClickHandle = useCallback((e) => {
    console.log(e);
  }, []);

  return (
    <Dropdown
      menu={{ items: eventVisibleItems, onClick: eventVisibleSelectorClickHandle }}
      open={visibleEventTypesSelectorOpen}
      onOpenChange={(e, i) => {
        if (i.source === 'trigger') setVisibleEventTypesSelectorOpen(e);
      }}
    >
      {allEventChartIcon}
    </Dropdown>
  );
}, compare);
