import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

class Paginator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      // Optionally you can pass defaultSortField and defaultSortOrder as props
      sortField: props.defaultSortField || null,
      sortOrder: props.defaultSortOrder || 'asc'
    };

    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setPage = this.setPage.bind(this);
  }

  // A helper to get the field value.
  // Adjust this if you have nested values (e.g., sort by organization.name).
  getFieldValue(item, field) {
    let value = item[field];
    // If the value is an object with a "name" property, use that.
    if (value && typeof value === 'object' && value.name) {
      value = value.name;
    }
    return value || '';
  }

  // Sort the entire data array based on sortField and sortOrder.
  getSortedData() {
    const { data } = this.props;
    const { sortField, sortOrder } = this.state;
    let sortedData = [...data];
    if (sortField) {
      sortedData.sort((a, b) => {
        let aValue = this.getFieldValue(a, sortField).toString().toLowerCase();
        let bValue = this.getFieldValue(b, sortField).toString().toLowerCase();
        const comp = aValue.localeCompare(bValue);
        return sortOrder === 'asc' ? comp : -comp;
      });
    }
    return sortedData;
  }

  // Return the paginated (limited) data from the sorted array.
  getLimitedData() {
    const sortedData = this.getSortedData();
    const { page } = this.state;
    const { limit } = this.props;
    const offset = (page - 1) * limit;
    return sortedData.slice(offset, offset + limit);
  }

  // Call this to change the sort field.
  // If the same field is clicked, toggle the sort order.
  handleSort = (field) => {
    this.setState(prevState => {
      let sortOrder = 'asc';
      if (prevState.sortField === field) {
        sortOrder = prevState.sortOrder === 'asc' ? 'desc' : 'asc';
      }
      // Reset to page 1 on sort change.
      return { sortField: field, sortOrder, page: 1 };
    });
  };

  handlePrevious() {
    this.setState(prevState => ({
      page: Math.max(prevState.page - 1, 1)
    }));
  }

  handleNext() {
    const sortedData = this.getSortedData();
    const maxPages = Math.ceil(sortedData.length / this.props.limit);
    this.setState(prevState => ({
      page: Math.min(prevState.page + 1, maxPages)
    }));
  }

  setPage(event) {
    const page = parseInt(event.target.id, 10);
    this.setState({ page });
  }

  // Render pagination page numbers.
  renderPageItems() {
    const sortedData = this.getSortedData();
    const maxPages = Math.ceil(sortedData.length / this.props.limit);
    const currentPage = this.state.page;
    let min = Math.max(1, currentPage - 2);
    let max = Math.min(maxPages, currentPage + 2);
    const pages = [];
    for (let i = min; i <= max; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <button id={i} className="page-link" onClick={this.setPage}>
            {i}
          </button>
        </li>
      );
    }
    return pages;
  }

  render() {
    const sortedData = this.getSortedData();
    if (!this.props.data || this.props.data.length === 0) {
      return <div>No data found.</div>;
    }

    // Get the limited (paginated) and sorted data.
    const limitedData = this.getLimitedData();

    return (
      <div>
        {/* 
          Call the render callback with two arguments:
          1. The paginated sorted data.
          2. An object with sorting state and a sort handler.
             Your table component can use this to render clickable headers.
        */}
        {this.props.render(limitedData, {
          sortField: this.state.sortField,
          sortOrder: this.state.sortOrder,
          handleSort: this.handleSort
        })}
        <ul className="pagination justify-content-center">
          <li className="page-item">
            <button className="page-link" onClick={this.handlePrevious}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </button>
          </li>
          {this.renderPageItems()}
          <li className="page-item">
            <button className="page-link" onClick={this.handleNext}>
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default Paginator;
