// EditDeviceModal.js
import React, { Component } from 'react';
import axios from 'axios';

class EditDeviceModal extends Component {
  constructor(props) {
    super(props);
    const { device } = props;
    this.state = {
      serialNumber: device.serialNumber || '',
      internalId: device.internalId || '',
      organizationId: device.organizationId || '',
      hubId: device.hub ? device.hub.id : '', // Initialize hubId based on current assignment
      errorMessage: '',
      organizations: [], // Initialize organizations if not passed
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchOrganizations = this.fetchOrganizations.bind(this);
  }

  componentDidMount() {
    this.fetchOrganizations();
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    console.log(`Input Change - Name: ${name}, Value: ${value}`);
    this.setState({ [name]: value }, () => {
      console.log('Updated state after input change:', this.state);
    });
  }

  fetchOrganizations() {
    // Fetch organizations if not passed as a prop
    if (!this.props.organizations) {
      axios.get('/organizations')
        .then((response) => {
          this.setState({ organizations: response.data.rows }, () => {
            console.log('Fetched organizations:', this.state.organizations);
          });
        })
        .catch((error) => {
          console.error('Error fetching organizations:', error);
        });
    } else {
      this.setState({ organizations: this.props.organizations }, () => {
        console.log('Organizations from props:', this.state.organizations);
      });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { serialNumber, internalId, organizationId, hubId } = this.state;
    const { device, hubs } = this.props; // Ensure 'hubs' are passed as props

    console.log('Submitting form with data:', { serialNumber, internalId, organizationId, hubId });

    // Basic validation
    if (!serialNumber || !internalId || !organizationId) {
      this.setState({ errorMessage: 'Please fill out all required fields.' });
      return;
    }

    // Prepare data for device update
    const deviceData = {
      serialNumber,
      internalId,
      organizationId,
    };

    try {
      // Update device information
      const deviceResponse = await axios.put(`/devices/${device.id}`, deviceData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Device updated successfully:', deviceResponse.data);

      // If a hub is selected, assign it to the device
      if (hubId) {
        // Check if the device already has a hub assigned
        if (device.hubId && device.hubId !== hubId) {
          // Unassign the existing hub
          await axios.put(`/hubs/${device.hubId}`, { deviceId: null }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log(`Unassigned hub with id ${device.hubId} from device ${device.id}`);
        }

        // Assign the new hub to the device
        const hubData = {
          deviceId: device.id, // Assign to this device
        };

        const hubResponse = await axios.put(`/hubs/${hubId}`, hubData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Hub assigned successfully:', hubResponse.data);
      }

      // After successful updates, notify the parent component and close the modal
      this.props.onDeviceUpdated();
      this.setState({ errorMessage: '' });
      this.props.closeModal();
    } catch (error) {
      console.error('Error updating device or assigning hub:', error);
      this.setState({ errorMessage: 'Failed to update device or assign hub.' });
    }
  }

  render() {
    const { serialNumber, internalId, organizationId, hubId, errorMessage, organizations } = this.state;
    const { hubs = [], closeModal } = this.props; 

    console.log('Rendering EditDeviceModal with props:', this.props);
    console.log('Rendering EditDeviceModal with state:', this.state);

    return (
      <>
        {/* Modal Backdrop */}
        <div className="modal-backdrop show"></div>

        {/* Modal */}
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: 'block' }}
          aria-modal="true"
          onClick={closeModal}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Edit Device</h3>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="modal-body">
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <div className="form-group">
                    <label htmlFor="serialNumber">Serial Number</label>
                    <input
                      type="text"
                      className="form-control bg-light"
                      id="serialNumber"
                      name="serialNumber"
                      value={serialNumber}
                      onChange={this.handleInputChange}
                      required
                      disabled  // Make this field uneditable
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="internalId">Internal ID</label>
                    <input
                      type="text"
                      className="form-control bg-light"
                      id="internalId"
                      name="internalId"
                      value={internalId}
                      onChange={this.handleInputChange}
                      required
                      disabled  // Make this field uneditable
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="organizationId">Organization</label>
                    <select
                      className="form-control"
                      id="organizationId"
                      name="organizationId"
                      value={organizationId}
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="">Select an organization</option>
                      {organizations.map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="hubId">Hub</label>
                    <select
                      className="form-control bg-light"
                      id="hubId"
                      name="hubId"
                      value={hubId}
                      onChange={this.handleInputChange}
                      disabled  // Make this field uneditable
                    >
                      <option value="">-- Select a Hub --</option>
                      {hubs.map((hub) => (
                        <option key={hub.id} value={hub.id}>
                          {hub.serialNumber} ({hub.internalId})
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Additional fields can be added here */}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Update Device
                  </button>
                  <button type="button" className="btn btn-secondary" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditDeviceModal;
