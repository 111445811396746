import React, { Component } from 'react';
import ReportAlertSettings from './report-alert-settings';
import EventSettings from './event-settings';
import UIDDisplay from './udi.js';

import $ from 'jquery';

class Settings extends Component {
  constructor(props) {
    super(props);
    // Naming convention for all fields must follow this convention:
    // category + tier + rangePlacement
    this.state = {
      organization: {},
      showEventSettings: false,
      RDIMildAlert: true,
      RDIModerateAlert: true,
      RDISevereAlert: true,
      CTMildAlert: true,
      CTModerateAlert: true,
      CTSevereAlert: true,
      BDMildAlert: true,
      BDSevereAlert: true,
      hasInvalidValues: false,
      alertType: 'alert-success',
      udi: 'G642SATCLOUD0',

      changesMade: false,
      saveConfirmText: '',
      saveVerified: false,
      modalContent: '',

      showPostOP: false,
      showRDIAlert: true,
      showCTAlert: false,
      showBDAlert: true,
      fields: {
        RDIMildLow: {
          category: 'RDI',
          tier: 'Mild',
          value: 5,
          rangePlacement: 'Low',
        },
        RDIMildHigh: {
          category: 'RDI',
          tier: 'Mild',
          value: 15,
          rangePlacement: 'High',
        },
        RDIModerateLow: {
          category: 'RDI',
          tier: 'Moderate',
          value: 15,
          rangePlacement: 'Low',
        },
        RDIModerateHigh: {
          category: 'RDI',
          tier: 'Moderate',
          value: 30,
          rangePlacement: 'High',
        },
        RDISevereLow: {
          category: 'RDI',
          tier: 'Severe',
          value: 30,
          rangePlacement: 'Low',
        },
        BDMildLow: {
          category: 'BD',
          tier: 'Mild',
          value: 88,
          rangePlacement: 'Low',
        },
        BDMildHigh: {
          category: 'BD',
          tier: 'Mild',
          value: 92,
          rangePlacement: 'High',
        },
        BDSevereHigh: {
          category: 'BD',
          tier: 'Severe',
          value: 88,
          rangePlacement: 'High',
        },
        CTMildLow: {
          category: 'CT',
          tier: 'Mild',
          value: 0,
          rangePlacement: 'Low',
        },
        CTMildHigh: {
          category: 'CT',
          tier: 'Mild',
          value: 0,
          rangePlacement: 'High',
        },
        CTModerateLow: {
          category: 'CT',
          tier: 'Moderate',
          value: 0,
          rangePlacement: 'Low',
        },
        CTModerateHigh: {
          category: 'CT',
          tier: 'Moderate',
          value: 0,
          rangePlacement: 'High',
        },
        CTSevereLow: {
          category: 'CT',
          tier: 'Severe',
          value: 0,
          rangePlacement: 'Low',
        },
        ODI4SevereLow: {
          category: 'ODI4',
          tier: 'Severe',
          value: 28.5,
          rangePlacement: 'Low',
        },
        CT90SevereLow: {
          category: 'CT90',
          tier: 'Severe',
          value: 7.2,
          rangePlacement: 'Low',
        },
        MeanSPO2SevereHigh: {
          category: 'MeanSp02',
          tier: 'Severe',
          value: 92.7,
          rangePlacement: 'Low',
        },
      },
    };
  }

  componentDidMount() {
    this.getOrganization();
  }

  getOrganization() {
    var success = this.handleData.bind(this);
    var failure = this.errorHandler.bind(this);
    $.ajax({
      method: 'GET',
      url: '/organizations/' + this.props.organization.id,
    })
      .done(success)
      .fail(failure);
  }

  handleData(data) {
    let settings = data.reportAlertSettings;
    if (typeof settings === 'string') {
      settings = JSON.parse(settings);
    }
    if (!settings) {
      settings = {
        showPostOP: false,
        showRDIAlert: true,
        showCTAlert: false,
        showBDAlert: true,
        fields: this.state.fields,
      };
    }
    let modifiedFields = this.state.fields;
    let fields = settings.fields;
    for (const category in fields) {
      let value = fields[category];
      if (!value) {
        value = 0;
      }
      if (modifiedFields[category]) {
        modifiedFields[category].value = parseFloat(value);
      }
    }
    this.setState({
      organization: data,
      fields: modifiedFields,
      showBDAlert: settings.showBDAlert,
      showCTAlert: settings.showCTAlert,
      showPostOP: settings.showPostOP,
      showRDIAlert: settings.showRDIAlert,
    });
  }

  errorHandler(error) {
    var body = error.responseJSON;
    console.log(body);
  }

  initAlertMessage(message, type) {
    this.setState({
      alertMessage: message,
      alertType: type,
    });
  }

  removeAlertHandler() {
    this.setState({
      alertMessage: null,
    });
  }

  save() {
    const success = this.saveConfirmed.bind(this);
    let newAlertSettings = {
      showPostOP: this.state.showPostOP,
      showRDIAlert: this.state.showRDIAlert,
      showCTAlert: this.state.showCTAlert,
      showBDAlert: this.state.showBDAlert,
      fields: {},
    };
    for (const field in this.state.fields) {
      const element = this.state.fields[field];
      newAlertSettings.fields[field] = parseFloat(element.value);
    }
    $.ajax({
      method: 'PUT',
      url: '/organizations/' + this.props.organization.id,
      data: {
        reportAlertSettings: JSON.stringify(newAlertSettings),
      },
    }).done(success);
  }

  verifySave(event) {
    if (event.target.value === 'CONFIRM') {
      this.setState({
        saveVerified: true,
        saveConfirmText: event.target.value,
      });
    } else {
      this.setState({
        saveVerified: false,
        saveConfirmText: event.target.value,
      });
    }
  }

  saveConfirmed() {
    this.setState({
      alertType: 'alert-success',
      alertMessage: 'Save Successful',
      changesMade: false,
      saveConfirmText: '',
    });
  }

  initiateSaveConfirmation() {
    if (this.state.hasInvalidValues) {
      this.setState({
        alertMessage: 'Please correct all settings errors before saving',
        alertType: 'alert-danger',
      });
    } else {
      this.save();
    }
  }

  setAlerts() {
    let newAlertState = {
      RDIMildAlert: true,
      RDIModerateAlert: true,
      RDISevereAlert: true,
      CTMildAlert: true,
      CTModerateAlert: true,
      CTSevereAlert: true,
      BDMildAlert: true,
      BDSevereAlert: true,
      hasInvalidValues: false,
    };
    // Loop through all fields in state
    for (const field in this.state.fields) {
      if (Object.hasOwnProperty.call(this.state.fields, field)) {
        const data = this.state.fields[field];
        const alertState = data.category + data.tier + 'Alert';

        let mildLow = data.category + 'MildLow';
        let mildHigh = data.category + 'MildHigh';
        let moderateLow = data.category + 'ModerateLow';
        let moderateHigh = data.category + 'ModerateHigh';
        if (data.category === 'BD') {
          if (data.rangePlacement === 'High' && data.tier === 'Mild') {
            const opposingPlacement = data.category + data.tier + 'Low';
            if (this.state.fields[opposingPlacement].value > data.value) {
              newAlertState[alertState] = false;
              newAlertState.hasInvalidValues = true;
            }
          } else if (data.rangePlacement === 'Low') {
            const highPlacement = data.category + data.tier + 'High';
            if (this.state.fields[highPlacement].value < data.value) {
              newAlertState[alertState] = false;
              newAlertState.hasInvalidValues = true;
            }
          } else if (data.tier === 'Severe') {
            if (
              data.value > this.state.fields[mildLow].value ||
              data.value > this.state.fields[mildHigh].value
            ) {
              newAlertState[alertState] = false;
              newAlertState.hasInvalidValues = true;
            }
          }
        } else {
          if (data.tier === 'Mild') {
            // If mildLow is greater than mildHigh -- alert
            if (data.rangePlacement === 'Low') {
              let opposingPlacement = data.category + data.tier + 'High';
              if (this.state.fields[opposingPlacement].value < data.value) {
                newAlertState[alertState] = false;
                newAlertState.hasInvalidValues = true;
              }
              // If mildHigh is greater than moderateLow -- alert
            } else {
              if (this.state.fields[moderateLow].value < data.value) {
                newAlertState[alertState] = false;
                newAlertState.hasInvalidValues = true;
              }
            }
          } else if (data.tier === 'Moderate') {
            // If moderateLow is greater than moderateHigh -- alert
            if (data.rangePlacement === 'Low') {
              if (data.value > this.state.fields[moderateHigh].value) {
                newAlertState[alertState] = false;
                newAlertState.hasInvalidValues = true;
              }
            }
            // If moderateHigh is greater than Severe -- alert
          } else if (data.tier === 'Severe') {
            if (this.state.fields[moderateLow]) {
              if (
                data.value < this.state.fields[mildLow].value ||
                data.value < this.state.fields[mildHigh].value ||
                data.value < this.state.fields[moderateHigh].value ||
                data.value < this.state.fields[moderateLow].value
              ) {
                newAlertState[alertState] = false;
                newAlertState.hasInvalidValues = true;
              }
            } else if (this.state.fields[mildLow]) {
              if (
                data.value < this.state.fields[mildLow].value ||
                data.value < this.state.fields[mildHigh].value
              ) {
                newAlertState[alertState] = false;
                newAlertState.hasInvalidValues = true;
              }
            }
          }
        }
      }
    }
    this.setState(newAlertState);
  }

  toggleAlertAppearance(event) {
    $('#toggleAlertDropdown').dropdown('toggle');
    this.setState({
      [event.target.id]: !this.state[event.target.id],
      changesMade: true,
    });
  }

  changeHandler(event) {
    const changingField = this.state.fields[event.target.name];
    var fields = this.state.fields;
    changingField.value = parseFloat(event.target.value);
    if (changingField.value > 100) changingField.value = 100;
    if (changingField.value < 0) changingField.value = 0;
    if (!changingField.value) changingField.value = 0;
    fields[event.target.name] = changingField;
    this.setState(
      {
        fields: fields,
        changesMade: true,
      },
      this.setAlerts.bind(this),
    );
  }

  resetToDefaults() {
    this.setState({
      showPostOP: false,
      showRDIAlert: true,
      showCTAlert: false,
      showBDAlert: true,
      fields: {
        RDIMildLow: {
          category: 'RDI',
          tier: 'Mild',
          value: 5,
          rangePlacement: 'Low',
        },
        RDIMildHigh: {
          category: 'RDI',
          tier: 'Mild',
          value: 15,
          rangePlacement: 'High',
        },
        RDIModerateLow: {
          category: 'RDI',
          tier: 'Moderate',
          value: 15,
          rangePlacement: 'Low',
        },
        RDIModerateHigh: {
          category: 'RDI',
          tier: 'Moderate',
          value: 30,
          rangePlacement: 'High',
        },
        RDISevereLow: {
          category: 'RDI',
          tier: 'Severe',
          value: 30,
          rangePlacement: 'Low',
        },
        BDMildLow: {
          category: 'BD',
          tier: 'Mild',
          value: 88,
          rangePlacement: 'Low',
        },
        BDMildHigh: {
          category: 'BD',
          tier: 'Mild',
          value: 92,
          rangePlacement: 'High',
        },
        BDSevereHigh: {
          category: 'BD',
          tier: 'Severe',
          value: 88,
          rangePlacement: 'High',
        },
        CTMildLow: {
          category: 'CT',
          tier: 'Mild',
          value: 0,
          rangePlacement: 'Low',
        },
        CTMildHigh: {
          category: 'CT',
          tier: 'Mild',
          value: 0,
          rangePlacement: 'High',
        },
        CTModerateLow: {
          category: 'CT',
          tier: 'Moderate',
          value: 0,
          rangePlacement: 'Low',
        },
        CTModerateHigh: {
          category: 'CT',
          tier: 'Moderate',
          value: 0,
          rangePlacement: 'High',
        },
        CTSevereLow: {
          category: 'CT',
          tier: 'Severe',
          value: 0,
          rangePlacement: 'Low',
        },
        ODI4SevereLow: {
          category: 'ODI4',
          tier: 'Severe',
          value: 28.5,
          rangePlacement: 'Low',
        },
        CT90SevereLow: {
          category: 'CT90',
          tier: 'Severe',
          value: 7.2,
          rangePlacement: 'Low',
        },
        MeanSPO2SevereHigh: {
          category: 'MeanSp02',
          tier: 'Severe',
          value: 92.7,
          rangePlacement: 'Low',
        },
      },
      changesMade: true,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center justify-content-between">
            <div className="col">
              <span className="h4">Settings</span>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div
                className="nav flex-md-column nav-pills mt-2"
                aria-orientation="vertical"
                style={{ height: '100%' }}
              >
                <div className="row d-flex flex-column align-items-center m-1">
                  <button
                    onClick={() => {
                      this.setState({ showEventSettings: false });
                    }}
                    className={
                      'btn ' +
                      (!this.state.showEventSettings ? 'border-primary' : '')
                    }
                  >
                    Report Alerts
                  </button>
                  <button
                    onClick={() => {
                      this.setState({ showEventSettings: true });
                    }}
                    className={
                      'btn ' +
                      (this.state.showEventSettings ? 'border-primary' : '')
                    }
                  >
                    Event Settings
                  </button>
                </div>
              </div>
              <div id="viewport" className="col">
                {this.state.showEventSettings ? (
                  <EventSettings />
                ) : (
                  <ReportAlertSettings
                    organization={this.props.organization}
                  ></ReportAlertSettings>
                )}
              </div>
              <div>
                  <UIDDisplay udi={this.state.udi} />
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
